<template>
    <div class="conct" @click="openChatWindow">
      <img :src="require('/static/images/zixun.png')"  alt="">
      <div>在线咨询</div>
    </div>
</template>

<script>
import layer from "layui-layer";
    export default {
        name: "else",
        components:{

        },
        data(){
          return {
           
          }
        },
        methods:{
             LayerOpen(title, url, width, height) {
			           layer.open({
			                type: 2,
			                title: title,
			                shadeClose: true,
			                shade: 0,
			                offset: "auto",
			                area: [width + 'px', height + 'px'],
			                content: url //iframe的url
			            });
			        },
			       openChatWindow() {
								//  layer.closeAll()
								this.LayerOpen("在线客服", "https://chatlink.mstatik.com/widget/standalone.html?eid=6137f9552f6e2810ce4a5959d7d71084&agentid=dc14ff41af0d0fd424b1013c2726d38b", 690,620);
								_MEIQIA('hidePanel')
						},
        }
    }
</script>

<style scoped>
.conct{
  width: 70px;
    height: 86px;
    position: fixed;
    right: 15px;
    top: 300px;
    text-align: center;
    font-size: 14px;
    background: white;
    box-shadow: 0 0 18px rgb(0 0 0 / 10%);
    cursor: pointer;
    z-index: 20000;
}
.conct>img{
  width: 35px;
  height: 35px;
  margin: 10px 0 5px;
 
}

</style>
